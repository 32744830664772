import React from 'react';
// import { Link } from 'react-router-dom';
// import { Button, Typography } from 'src/components/common/Base';
// import { ThemeBg, VectorHeadphone, Checked } from 'src/assets';
import { GroupImage, SplitSlider1 } from 'src/assets';
import { InnerPageBanner, OurBrand, TwoSideFullWidthSection } from 'src/components/common/Widgets';

import './style.scss';
// import './libs.js';

const AboutUsDefault = () => {

    // Inner Banner
    const pageTitle = 'About Us';
    const backgroundImageUrl = GroupImage;

    // About Us Section
    const twoSideSecLeftSideImg = SplitSlider1;
    const twoSideSecBadge = "About US";
    const twoSideSecTitle = 'Change The Way You Do Business With Our Brand';
    const twoSideSecDescription = "Our journey began in 2016 with a team of seven visionaries. Since then, we have transformed into a comprehensive mobile-centric software development company. Our success is marked by the highest team satisfaction in the industry, recognition from independent analysts, and a growing list of delighted clients. Some notable collaborations include Qvise Pvt Ltd, Tramiq International Ltd, Space Time International LTD UK, and Tramtech Solution Ltd, UK. Our expertise extends to assisting numerous startups in adopting cutting-edge technologies, ensuring they stay at the forefront of innovation. Choose Genius System Logix for a partnership that turns concepts into reality and propels your business into the future.";
    const twoSideSecList1 = 'Revolutionise the way your business operates.';
    const twoSideSecList2 = 'Revolutionise the way your business operates.';
    const twoSideSecList3 = 'Revolutionise the way your business operates.';

    return (
        <>
            <div className='about-us-page'>
                <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />
                <TwoSideFullWidthSection
                    twoSideSecLeftSideImg={twoSideSecLeftSideImg}
                    twoSideSecBadge={twoSideSecBadge}
                    twoSideSecTitle={twoSideSecTitle}
                    twoSideSecDescription={twoSideSecDescription}
                    twoSideSecList1={twoSideSecList1}
                    twoSideSecList2={twoSideSecList2}
                    twoSideSecList3={twoSideSecList3}
                />

                <OurBrand />

                {/* <section className='paddingY-100'>
                    <div className="ah-container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="description text-center">
                                    <div className="badge">About Us</div>
                                    <h2>We're Available To Help <span>Anytime, Anywhere</span></h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="our-growth grwt2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 sticky-box">
                            <div className="info-grow">
                                <h6 className="subhead">
                                    Our Services
                                </h6>
                                <h2 className="heading">
                                    Empowering our partners to embrace the forefront of innovation
                                </h2>
                                <h6 className="subhead">
                                    Together, with our modern solutions, let’s reimagine the processes propelling business towards unparalleled success.
                                </h6>
                                <a href="/" className="btn btn-blue various"> Let’s Build Together</a>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1">
                            <div className="info-grow2">
                                <ul>
                                    <li>
                                        <h6 className="subhead">
                                            Visual Designs
                                        </h6>
                                        <p>
                                            Our visual designs transcend the confined boundaries of aesthetics, weaving narratives through the language of visuals while embracing functionality.
                                        </p>
                                        <a href="/" className="btn-trans"> learn more</a>
                                    </li>
                                    <li>
                                        <h6 className="subhead">
                                            Web Design &amp; Development
                                        </h6>
                                        <p>
                                            We help in designing and developing high-performing, digitally transformative, and user-friendly websites to fuel the growth of your enterprise.
                                        </p>
                                        <a href="/" className="btn-trans"> learn more</a>
                                    </li>
                                    <li>
                                        <h6 className="subhead">
                                            App Development
                                        </h6>
                                        <p>
                                            We help enterprises around the globe in developing functional and user-friendly apps, providing guidance from idea to concept to ongoing support.
                                        </p>
                                        <a href="/" className="btn-trans"> learn more</a>
                                    </li>
                                    <li>
                                        <h6 className="subhead">
                                            Software Development
                                        </h6>
                                        <p>
                                            We help the enterprise strategize new ideas and accelerate the value by providing custom software development solutions, product engineering, and QA testing.
                                        </p>
                                        <a href="/" className="btn-trans"> learn more</a>
                                    </li>
                                    <li>
                                        <h6 className="subhead">
                                            AI/ML Solutions
                                        </h6>
                                        <p>
                                            We assist businesses in transforming data into useful insights by integrating advanced ML solutions, generative AI, analytics, and connected intelligence.
                                        </p>
                                        <a href="/" className="btn-trans"> learn more</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            </div>
        </>
    );
};

export default AboutUsDefault;
