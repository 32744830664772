import React from 'react';
import { Link } from 'react-router-dom';
import { BrandIcon1, BrandIcon2, BrandIcon3, BrandIcon4, Product, RisingStars } from 'src/assets';
import { Typography, Button } from 'src/components/common/Base';
import './style.scss';

const OurBrand = () => {

    return (
        <>
            <section className='ourBrand-sec bg-light' id='ourBrand-sec'>
                <div className="ah-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 ">
                            <div className="ourBrandMedia">
                                <img className={`desktop animate__fadeInUp animate__animated animate__slow img-fluid`} src={Product} alt="Product" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Our Brand</span></div>
                            <br />
                            <h2>Our Brand <span>Can Help</span> Your Company Have A Better Future.</h2>
                            {/* <h2>What makes us <span> valuable</span> to our clients</h2> */}
                            <p>With our cutting-edge technology and expert solutions, we pave the way for your company's future success, ensuring innovation and growth every step of the way. Trust us to transform your vision into reality and propel your business towards a brighter tomorrow.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <figure className='ourBrand-services'>
                                        <img className={`desktop animate__fadeInUp animate__animated animate__slow img-fluid`} style={{ width: '75px', height: '68px' }} src={BrandIcon1} alt="BrandIcon1" />
                                        <figcaption>
                                            <h4>Goal Enhancements</h4>
                                            <p>Elevating your software goals to new heights through innovative enhancements and tailored solutions.</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-md-6">
                                    <figure className='ourBrand-services'>
                                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '75px', height: '68px' }} src={BrandIcon2} alt="BrandIcon2" />
                                        <figcaption>
                                            <h4>Advanced Tools</h4>
                                            <p>Empowering your journey with cutting-edge tools for unparalleled efficiency and innovation</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-md-6">
                                    <figure className='ourBrand-services'>
                                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '75px', height: '68px' }} src={BrandIcon3} alt="BrandIcon3" />
                                        <figcaption>
                                            <h4>Safe Transaction</h4>
                                            <p>Ensuring peace of mind through secure transactions, safeguarding your digital assets every step of the way.</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-md-6">
                                    <figure className='ourBrand-services'>
                                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '75px', height: '68px' }} src={BrandIcon4} alt="BrandIcon4" />
                                        <figcaption>
                                            <h4>Tailored Optimization</h4>
                                            <p>Unlocking tailored solutions that optimize your digital ecosystem, driving unparalleled efficiency and growth.</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-12 mt-4">
                                    <Link to="/about-us" className='desktop animate__fadeInUp animate__animated animate__slow'>
                                        <Button className="btn d-btn" color="primary">
                                            <Typography variant="button-text">More Details</Typography>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurBrand;
