import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { logo, textLogo, toggle } from 'src/assets';
import { logo, toggle, WhiteLogo } from 'src/assets';
import { Button, Typography } from '../../Base';
// import { Typography } from '../../Base';
import './style.scss';
import PropTypes from 'prop-types';

const Navbar = ({ isComingSoon }) => {
	const [isSticky, setIsSticky] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const handleScroll = () => {
			const offset = window.scrollY;
			if (offset > 200) { // Adjust this value based on when you want the navbar to become sticky
				setIsSticky(true);
			} else {
				setIsSticky(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		// const loggedInUserId = localStorage.getItem("loggedInUserId");
		// setIsLoggedIn(loggedInUserId);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};

	}, []);

	useEffect(() => {
		// Check if the user is logged in when the component mounts
		const loggedInUserId = localStorage.getItem("loggedInUserId");
		setIsLoggedIn(!!loggedInUserId);
	}, []);


	const handleLogout = () => {
		// Perform logout logic
		localStorage.removeItem("loggedInUserId"); // Remove user ID from localStorage
		setIsLoggedIn(false); // Update isLoggedIn state to false
		navigate('/login'); // Navigate to the login page
	};


	return (
		<>
			<nav className={isSticky ? 'navbar navbar-expand-lg bg-body-tertiary sticky' : 'navbar navbar-expand-lg bg-body-tertiary'}>
				<div className="container-fluid">
					<Link className="navbar-brand" to="/">
						<img src={WhiteLogo} alt="WhiteLogo" width={120} className="for-stuck-nav img-fluid logo me-3" />
						<img src={logo} alt="logo" width={120} className="for-sticky-nav img-fluid logo me-3" />
						{/* <img src={textLogo} alt="Text logo" className="img-fluid text-logo" /> */}
					</Link>
					<button
						className={`${isComingSoon && 'invisible'} navbar-toggler`}
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<img src={toggle} alt="" />
					</button>

					<div className={`${isComingSoon && 'invisible'} collapse navbar-collapse`} id="navbarSupportedContent">
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link" to={'/about-us'}>
									<Typography variant="small-text">About Us</Typography>
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={'/Services'}>
									<Typography variant="small-text">Services</Typography>
								</Link>
							</li>
							{/* <li className="nav-item">
								<Link className="nav-link" to={'/'}>
									<Typography variant="small-text">Industries</Typography>
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={'/'}>
									<Typography variant="small-text">Customers</Typography>
								</Link>
							</li> */}
							<li className="nav-item">
								<Link className="nav-link" to="/careers">
									<Typography variant="small-text">Careers</Typography>
								</Link>
							</li>
							{/* <li className="nav-item">
								<Link className="nav-link" to={'/contact-us'}>
									<Typography variant="small-text">Contact Us</Typography>
								</Link>
							</li> */}
							{isLoggedIn && (
								<>
									<li className="nav-item">
										<Link className="nav-link" to={'/post-job'}>
											<Typography variant="small-text">Post Job</Typography>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to="/contact-us">
											<Typography variant="small-text">Contact Us</Typography>
										</Link>
									</li>
								</>
							)}
						</ul>
					</div>
					<div className="signup">
						{isLoggedIn ? (
							<Button className="btn d-btn" color="primary" onClick={handleLogout}>
								<Typography variant="button-text">Logout</Typography>
							</Button>
						) : (
							<Link to="/contact-us">
								<Button className="btn d-btn" color="primary">
									<Typography variant="button-text">Contact Us</Typography>
								</Button>
							</Link>
						)}
					</div>
				</div>
			</nav>
		</>
	);
};

Navbar.propTypes = {
	isComingSoon: PropTypes.bool
};

export default Navbar;
