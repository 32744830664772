import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OfficeBuilding, Location, Phone, Email, Website, RisingStars } from 'src/assets';
import "./style.scss";

const Address = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter' || event.key === ' ') {
            setActiveTab(index);
        }
    };
    if (!tabs) {
        return <div>No tabs data provided</div>;
    }

    // const iframeUrls = [
    //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.0975822553637!2d67.03278054180718!3d24.82633628713139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33dc64c7dcd5b%3A0x89de9a8f825dbb71!2sQVISE%20(Quality%20Vertex%20Integrated%20System%20Engineering)%20Pvt.%20Ltd.!5e0!3m2!1sen!2s!4v1709885604031!5m2!1sen!2s',
    //     // 'https://example.com/page2',
    //     // 'https://example.com/page3',
    // ];
    return (
        <>
            <section className='address-sec ah-pt-50 '>
                <div className="ah-container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-8">
                            <div className="description ah-mb-50">
                                <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Contact Us</span></div>
                                <br />
                                <h2>Discover Your <span>Nation's <br /> Closest Regional </span>Branches</h2>
                                <p>Easily find and connect with the nearest regional branch of your nation, providing you with convenient access to our services and support. Explore the map to discover the closest branch locations and their contact details.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="tabs ah-mb-50">
                                {tabs.map((tab, index) => (
                                    <div style={{ backgroundImage: `url(${OfficeBuilding})` }}
                                        key={index}
                                        className={`tab ${activeTab === index ? 'active' : ''}`}
                                        onClick={() => handleTabClick(index)}
                                        onKeyDown={(event) => handleKeyDown(event, index)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        {tab.title}
                                    </div>
                                ))}
                            </div>
                            <div className="tab-content">
                                <div className="address-content">
                                    <div className="ah-box left bg-theme-light">
                                        <h2>{tabs[activeTab].cityName}</h2>
                                        <p>{tabs[activeTab].content}</p>
                                        <div className='ourBrand-sec p-0'>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <figure className='ourBrand-services'>
                                                        <div className="address-icon">
                                                            <img src={Location} alt="Location" />
                                                        </div>
                                                        <figcaption>
                                                            <h4>Our Location</h4>
                                                            <p className='mb-0'>{tabs[activeTab].address}</p>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="col-md-12">
                                                    <figure className='ourBrand-services'>
                                                        <div className="address-icon">
                                                            <img src={Phone} alt="Phone" />
                                                        </div>
                                                        <figcaption>
                                                            <h4>Phone Number</h4>
                                                            {/* to={`https://wa.me/${tabs[activeTab].phone1}`} */}
                                                            <Link className="d-link" to={`tel:${tabs[activeTab].phone1}`}>{tabs[activeTab].phone1}</Link><p className='d-inline-block text-dark mx-2'> / </p><Link className="d-link" to={`tel:${tabs[activeTab].phone2}`}>{tabs[activeTab].phone2}</Link>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="col-md-12">
                                                    <figure className='ourBrand-services'>
                                                        <div className="address-icon">
                                                            <img src={Email} alt="Email" />
                                                        </div>
                                                        <figcaption>
                                                            <h4>Email</h4>
                                                            <Link className="d-link" to={`mailto:${tabs[activeTab].email1}`}>{tabs[activeTab].email1}</Link><p className='d-inline-block text-dark mx-2'> / </p><Link className="d-link" to={`mailto:${tabs[activeTab].email2}`}>{tabs[activeTab].email2}</Link>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="col-md-12">
                                                    <figure className='ourBrand-services'>
                                                        <div className="address-icon">
                                                            <img src={Website} alt="Website" />
                                                        </div>
                                                        <figcaption>
                                                            <h4>Website</h4>
                                                            <Link className="d-link" to={'https://www.geniuslogix.com'} target='/blank'>www.geniuslogix.com</Link><p className='d-inline-block text-dark mx-2'> / </p><Link className="d-link" to={'https://www.geniuslogix.com'} target='/blank'>www.geniuslogix.com</Link>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                {/* <div className="col-12 mt-4">
                                                    <Button className={`btn primary m-auto desktop animate__fadeInUp animate__animated animate__slow `} color={'link'} variant={'solid'}>
                                                        <Typography variant="button-text">More Details</Typography>
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ah-box right">
                                        <div className="iframe-container">
                                            <iframe src={tabs[activeTab].mapLocation} title="Map Location" width="100%" height="668px"> </iframe>
                                            {/* {iframeUrls.map((url, index) => (
                                                <iframe
                                                    key={index}
                                                    title={`Embedded iframe ${index + 1}`}
                                                    src={url}
                                                    width="100%"
                                                    height="633px"
                                                // frameBorder="0"
                                                />
                                            ))} */}
                                        </div>
                                        {/* <iframe title="referrerpolicy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.0975822553637!2d67.03278054180718!3d24.82633628713139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33dc64c7dcd5b%3A0x89de9a8f825dbb71!2sQVISE%20(Quality%20Vertex%20Integrated%20System%20Engineering)%20Pvt.%20Ltd.!5e0!3m2!1sen!2s!4v1709885604031!5m2!1sen!2s" width="100%" height="auto" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Address;